import React from "react";
import PropTypes from "prop-types";
import { SEO } from "../SEO";
import Navbar from "../Navbar";
import PageFooter from "../PageFooter";
import WindowSizeContext from "../../contexts/windowSizeContext";
import useWindowSize from "../../hooks/useWindowSize";
import IpGeoDataContext from "../../contexts/ipGeoDataContext";
import useIpGeoData from "../../hooks/useIpGeoData";
import useHasMounted from "../../hooks/useHasMounted";
import UnavailableLocation from "../UnavailableLocation";
import { shouldBlockUserBasedOn } from "../../services/GeoLocationService";

/**
 * The default layout component for MDX rendered pages (except blog posts).
 *
 * This layout page sets the WindowSizeContext from the useWindowSize hook.
 *
 * @param {Props} props Array of data passed to the function from the parent component and the rendering pipeline.
 * @returns (element) A layout page, configured with the meta data passed, which renders the child elements passed.
 */
const MdxPageLayout = (props) => {
  const { pageMetaData } = props;
  const { pageTitle, pageDescription, pageStrapLine, pageImagePath, pageMetaTags, showNavbar } = pageMetaData;
  const windowSize = useWindowSize();
  const ipGeoData = useIpGeoData();
  const hasMounted = useHasMounted();

  if (ipGeoData.isLoading === true || !hasMounted) {
    return null;
  }

  if (shouldBlockUserBasedOn(ipGeoData)) {
    return <UnavailableLocation ipGeoData={ipGeoData} windowSize={windowSize.windowSize} />;
  }

  return (
    <WindowSizeContext.Provider value={windowSize}>
      <IpGeoDataContext.Provider value={ipGeoData}>
        <div className="flex flex-col min-h-screen border border-transparent font-body">
          <SEO title={pageTitle} description={pageDescription} strapLine={pageStrapLine} pageImagePath={pageImagePath} meta={pageMetaTags} />
          {showNavbar ? <header>{<Navbar />}</header> : null}
          <div className="content">{props.children}</div>
          <footer>
            <PageFooter />
          </footer>
        </div>
      </IpGeoDataContext.Provider>
    </WindowSizeContext.Provider>
  );
};

MdxPageLayout.propTypes = {
  children: PropTypes.array.isRequired,
  pageMetaData: PropTypes.object.isRequired,
};

export default MdxPageLayout;
